import { useMemo } from "react";
import { config } from "../config";
import { useParentArgsContext } from "../contexts/parent-args-context";
import { useUserContext } from "../contexts/user-context";
import { useInDemo } from "./use-in-demo";

export type Paywall = {
  songsLimit: number;
  suggestionsLimit: number;
  metricEnabled: boolean;
};

const PREMIUM: Paywall = {
  songsLimit: Infinity,
  suggestionsLimit: Infinity,
  metricEnabled: true,
};

const FREE: Paywall = {
  ...PREMIUM,
  suggestionsLimit:
    config.nSuggestionsPerFetch * config.freeSuggestionsProportion,
  metricEnabled: false,
};

export function usePaywall(): Paywall {
  const [user] = useUserContext();
  const inDemo = useInDemo();
  const { featureFlags } = useParentArgsContext();
  const isPremium = user?.subscriptionPlan === "premium" || user?.subscriptionPlan === "pro" || inDemo;

  const free = useMemo(
    () => ({ ...FREE, songsLimit: featureFlags.freeUserSongsLimit }),
    [featureFlags.freeUserSongsLimit]
  );

  return isPremium ? PREMIUM : free;
}
