import React, { createContext, useContext, useEffect } from "react";
import { User, useUser } from "../api/service";
import {
  SongRecovery,
  useRecoverLocallyStoredSong,
} from "../hooks/use-recover-locally-stored-song";
import { useRouter } from "next/router";
import { SpendFn, useStartUpTasks } from "../hooks/use-start-up-tasks";
import { useParentArgsContext } from "./parent-args-context";
import { ParentProps } from "../types/props";

export type UserContextValue = readonly [
  user: User | null,
  refetchUser: () => void,
  utils: {
    isPremium: boolean;
    songRecovery: SongRecovery;
    spendStartUpTask: (fn: SpendFn) => void;
  }
];

export const DEFAULT_USER_CONTEXT_VALUE: UserContextValue = [
  null,
  () => undefined,
  {
    isPremium: false,
    songRecovery: { hasData: false, status: "idle", songId: null },
    spendStartUpTask: () => undefined,
  },
];

export const UserContext = createContext<UserContextValue>(
  DEFAULT_USER_CONTEXT_VALUE
);

export function useUserContext() {
  return useContext(UserContext);
}

export function UserContextProvider({ children }: ParentProps) {
  const { task } = useParentArgsContext();
  const router = useRouter();
  const shouldNotWarnUnauthenticated =
    /^\/(viewer|demo|slate-demo|draftjs-demo|demo-welcome)(\/.*)?$/.test(
      router?.route
    );
  const [user, refetchUser] = useUser({
    supressNotAuthorizedAlert: shouldNotWarnUnauthenticated,
  });
  const [songRecovery, recoverSong] = useRecoverLocallyStoredSong();
  const acceptedTerms = user.value?.acceptedTerms;
  const spendStartUpTask = useStartUpTasks({ task, songRecovery });

  useEffect(() => {
    if (acceptedTerms) {
      recoverSong();
    }
  }, [acceptedTerms, recoverSong]);

  return (
    <UserContext.Provider
      value={[
        user.value,
        refetchUser,
        {
          isPremium: user.value?.subscriptionPlan === "premium" || user.value?.subscriptionPlan === "pro",
          songRecovery,
          spendStartUpTask,
        },
      ]}
    >
      {children}
    </UserContext.Provider>
  );
}
